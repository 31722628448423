import React, { useState, useEffect } from "react"

import { motion, useViewportScroll, useTransform } from "framer-motion"
import { useInView } from "react-intersection-observer"

export const ScaleBox = ({
  mano,
  children,
  delayOrder, // order of appearance
  duration = 0.4,
  easing = "easeIn", // [number, number, number, number] | "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate" | EasingFunction;
  ...style
}) => {
  const intersectionRef = React.useRef(null)
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })

  const variants = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    show: {
      scale: 1,
      opacity: 1,
    },
  }

  const [elementTop, setElementTop] = useState(0)
  const { scrollY } = useViewportScroll()

  const y = useTransform(scrollY, [elementTop, elementTop + 2], [0, -2], {
    clamp: false,
  })

  useEffect(() => {
    const element = intersectionRef.current
    setElementTop(element.offsetTop)
  }, [intersectionRef])

  return (
    <div ref={intersectionRef}>
      <motion.div
        ref={ref}
        className="overlay"
        variants={variants}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
        style={{ ...style, y }}
      >
        {children}
      </motion.div>
    </div>
  )
}
