import React, { useState, useEffect } from "react"
import CountUp from "react-countup"

// MUI
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { useInView } from "react-intersection-observer"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(6),
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  right: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const tiers = [
  {
    price: 75,
    description: ["Equipos Atendidos"],
  },
  {
    price: 500,
    description: ["Fallas Solucionadas"],
  },
  {
    price: 20,
    description: ["Clientes Mineros"],
  },
  {
    price: 400,
    description: ["Visitas a Faena"],
  },
]

export default function Pricing() {
  const classes = useStyles()
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (visible) return
  }, [visible])

  return (
    <>
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Grid container spacing={10} alignItems="flex-start">
          {tiers.map((tier, idx) => (
            <Grid item key={idx} xs={6} md={3}>
              <div className={classes.cardPricing} ref={ref}>
                +
                <Typography
                  component="h2"
                  variant="h1"
                  color="primary"
                  style={{ fontFamily: "Montserrat", fontWeight: "bold" }}
                >
                  {!inView ? (
                    tier.price
                  ) : (
                    <CountUp
                      key={idx}
                      end={tier.price}
                      duration={2.75}
                      useEasing={true}
                      useGrouping={true}
                      onComplete={() => setVisible(true)}
                    />
                  )}
                </Typography>
              </div>
              {tier.description.map(line => (
                <Typography variant="subtitle1" align="center" key={line}>
                  {line}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}
