import React, { useMemo } from "react"

import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

export const Parallax = ({
  children,
  delayOrder, // order of appearance
  duration = 0.4,
  easing = "easeIn",
}) => {
  const intersectionRef = React.useRef(null)
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })
  const transition = useMemo(
    () => ({
      duration,
      delay: delayOrder / 5,
      ease: easing,
    }),
    [duration, delayOrder, easing]
  )

  const variants = {
    hidden: {
      scale: 0,
      opacity: 0,
      translateY: -5,
      transition,
    },
    show: {
      scale: 1,
      opacity: 1,
      translateY: 0,
      transition,
    },
  }

  return (
    <div ref={intersectionRef}>
      <motion.div
        ref={ref}
        className="overlay"
        variants={variants}
        initial="hidden"
        animate={inView ? "show" : "hidden"}
      >
        {children}
      </motion.div>
    </div>
  )
}
