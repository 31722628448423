import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 4, 6),
    width: "100%",
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[200],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    backgroundCard: "transparent",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}))

const tiers = [
  {
    title: "Experiencia",
    detalle:
      "Hemos participado activamente junto a OEM, en la puesta en marcha de equipos mineros de diferentes países: Chile, Perú, Brasil, Canadá, EE.UU. y Australia.",
  },
  {
    title: "Conocimiento",
    detalle:
      "Conocemos en detalle los sistemas, partes y piezas de sus equipos. Ya que fuímos parte del equipo de diseño en Estados Unidos.",
  },
  {
    title: "Actitud",
    detalle:
      "Hacemos de su problemática nuestro desafío para lograr una solución eficaz y eficiente.",
  },
  {
    title: "Soporte",
    detalle:
      "Durante más de 20 años hemos entregado el mejor servicio en el ámbito del mantenimiento de equipos mineros, especializándonos en palas eléctro-mecánicas.",
  },
  {
    title: "Soluciones",
    detalle:
      "Cuando su flota se detiene, la producción se ve afectada. Mantener sus equipos operativos es nuestra prioridad",
  },
]

export default function Texto({ tier }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h2"
          variant="h2"
          color="primary"
          align="center"
          gutterBottom
        >
          {tiers[tier].title}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          component="p"
        >
          {tiers[tier].detalle}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={6} md={4}></Grid>
          ))}
        </Grid>
      </Container>
      {/* End footer */}
    </React.Fragment>
  )
}
