import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import logo from "../images/cobb_logo_rect.svg"
import ArrowDownward from "@material-ui/icons/KeyboardArrowDown"

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: "90vh",
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    zIndex: -1,
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
  },
  logo: {
    marginTop: theme.spacing(4),
    padding: 40,
    display: "block",
  },
}))

function ProductHeroLayout(props) {
  const { backgroundClassName, children } = props
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.logo}>
          <img src={logo} alt="logo" height={200} width="100%" />
        </div>
        {children}
        <div className={classes.backdrop} />
        <div className={clsx(classes.background, backgroundClassName)} />
        <ArrowDownward color="primary" />
      </Container>
    </section>
  )
}

ProductHeroLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ProductHeroLayout
