import React, { lazy, Suspense } from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import { Parallax } from "../components/parallax"
import ProductHero from "../components/ProductHero"
import Indicators from "../components/indicators"
import { ScaleBox } from "../components/scale-box"
import Texto from "../components/texto"
import Contacto from "../components/contacto"
import Clientes from "../components/clientes"

// MUI
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"

const Model = lazy(() => import("../components/gltf"))

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
  return (
    <>
      <CssBaseline />
      <Layout>
        <Seo title="Home" />
        <ProductHero />
        <Container>
          <Grid container>
            <Grid container item justifyContent="center">
              <ScaleBox>
                <Indicators />
                <br />
                <br />
              </ScaleBox>
            </Grid>
            <Grid container item xs={12} sm={6}>
              {!isSSR && (
                <Suspense fallback={<CircularProgress />}>
                  <Model
                    url="/pala_draco.glb"
                    color
                    position={[0, -4, 0]}
                    cameraProp={{ position: [0, -3, 8] }}
                  />
                </Suspense>
              )}
            </Grid>
            <Grid container item xs={12} sm={6}>
              <ScaleBox>
                <Texto tier={0} />
              </ScaleBox>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <ScaleBox>
                <Texto tier={1} />
              </ScaleBox>
            </Grid>
            <Grid container item xs={12} sm={4}>
              {!isSSR && (
                <Suspense fallback={<CircularProgress />}>
                  <Model
                    url="/cubes_draco.glb"
                    color
                    position={[0, -5, 0]}
                    cameraProp={{
                      position: [0, 0, 18],
                    }}
                  />
                </Suspense>
              )}
            </Grid>
            <Grid container item xs={12} sm={4}>
              <ScaleBox>
                <Texto tier={2} />
              </ScaleBox>
            </Grid>
            <Grid container item xs={12} sm={4}>
              <ScaleBox>
                <Texto tier={3} />
              </ScaleBox>
            </Grid>
            <Grid container item xs={12} sm={4}>
              {!isSSR && (
                <Suspense fallback={<CircularProgress />}>
                  <Model
                    url="/mano_draco.glb"
                    color
                    position={[0, -8, 0]}
                    cameraProp={{
                      position: [0, 0, 16],
                    }}
                  />
                </Suspense>
              )}
            </Grid>
            <Grid container item xs={12} sm={4}>
              <ScaleBox>
                <Texto tier={4} />
              </ScaleBox>
            </Grid>
            <Grid container item justifyContent="center">
              <div id="clientes" style={{ padding: "20px" }}>
                <Parallax>
                  <Clientes />
                </Parallax>
              </div>
            </Grid>
            <Grid container item justifyContent="center">
              <div id="contacto">
                <Parallax>
                  <Contacto />
                </Parallax>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  )
}

export default IndexPage
