import React from "react"

import cmcc_logo from "../images/bhp_cmcc_logo.svg"
import cmdic_logo from "../images/collahuasi_logo.svg"

// MUI
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

export default function SignUp() {
  return (
    <>
      <Typography component="h2" variant="h4" color="primary" align="center">
        Nuestros Clientes
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <figure>
            <img src={cmcc_logo} height={200} alt="cmcc-logo" />
          </figure>
        </Grid>
        <Grid item xs={12} sm={6}>
          <figure>
            <img src={cmdic_logo} height={200} alt="cmdic-logo" />
          </figure>
        </Grid>
      </Grid>
    </>
  )
}
