import React from "react"
import useForm from "./useForm"
import { navigate } from "gatsby-link"

// MUI
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  right: {
    marginRight: 0,
    paddingRight: 0,
  },
}))

export default function SignUp() {
  const classes = useStyles()
  const { state, handleOnChange, handleOnSubmit, disable } = useForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  )
  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h2" variant="h4" color="primary" align="center">
          Contáctenos
        </Typography>
        <form
          className={classes.form}
          noValidate
          name="contact"
          action="/gracias"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleOnSubmit}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <TextField
            required
            fullWidth
            autoComplete="fname"
            name="firstName"
            margin="normal"
            id="firstName"
            label="Nombre"
            value={state.firstName.value}
            onChange={handleOnChange}
            error={!!state.firstName.error}
            helperText={state.firstName.error}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="lastName"
            label="Apellido"
            name="lastName"
            autoComplete="lname"
            value={state.lastName.value}
            onChange={handleOnChange}
            error={!!state.lastName.error}
            helperText={state.lastName.error}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="empresa"
            label="Empresa"
            name="empresa"
            autoComplete="empresa"
            value={state.empresa.value}
            onChange={handleOnChange}
            error={!!state.empresa.error}
            helperText={state.empresa.error}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={state.email.value}
            onChange={handleOnChange}
            error={!!state.email.error}
            helperText={state.email.error}
          />
          <TextField
            required
            fullWidth
            id="mensaje"
            name="mensaje"
            label="Mensaje"
            type="text"
            margin="normal"
            multiline
            rows={4}
            value={state.mensaje.value}
            onChange={handleOnChange}
            error={!!state.mensaje.error}
            helperText={state.mensaje.error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={disable}
          >
            Enviar
          </Button>
          <Grid container>
            <Grid item xs />
            <Grid item className={classes.right}>
              <Link
                href="https://outlook.office365.com/owa/calendar/AsesoriasMinerasCGBB@amcgbb.onmicrosoft.com/bookings/"
                variant="body2"
              >
                Reserve aquí su hora
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

// Define your state schema
const stateSchema = {
  firstName: { value: "", error: "" },
  lastName: { value: "", error: "" },
  empresa: { value: "", error: "" },
  email: { value: "", error: "" },
  mensaje: { value: "", error: "" },
  botField: { value: "", error: "" },
}

// Define your validationStateSchema
// Note: validationStateSchema and stateSchema property
// should be the same in-order validation works!
const validationStateSchema = {
  firstName: {
    required: true,
    validator: {
      regEx: /^[a-zA-Z ]+$/,
      error: "Ingrese un nombre válido.",
    },
  },
  lastName: {
    required: true,
    validator: {
      regEx: /^[a-zA-Z ]+$/,
      error: "Ingrese un apellido válido.",
    },
  },
  empresa: {
    required: true,
    validator: {
      regEx: /(.|\n)*/,
      error: "Indique el nombre de su empresa.",
    },
    botField: {
      required: false,
      validator: {
        regEx: /^$/,
        error: "Eres un bot",
      },
    },
  },
  email: {
    required: true,
    validator: {
      regEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      error: "Ingrese un email válido.",
    },
  },
  mensaje: {
    required: true,
    validator: {
      regEx: /(.|\n)*/,
      error: "Ingrese un mensaje.",
    },
  },
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const onSubmitForm = (state, event) => {
  const form = event.target
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      "form-name": form.getAttribute("name"),
      firstName: state.firstName.value,
      lastName: state.lastName.value,
      empresa: state.empresa.value,
      email: state.email.value,
      mensaje: state.mensaje.value,
    }),
  })
    .then(() => navigate(form.getAttribute("action")))
    .catch(error => alert(error))
}
