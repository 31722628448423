import React from "react"
import ProductHeroLayout from "./ProductHeroLayout"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

const backgroundImage =
  "https://images.unsplash.com/photo-1567366010418-a999de023da6?auto=format&fit=crop&w=1400&q=80"

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center",
    /* Full height */
    height: "100%",
    /* Create the parallax scrolling effect */
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
}))

function ProductHero(props) {
  const classes = useStyles()

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <Typography color="primary" align="center" variant="h2" marked="center">
        Asesorías Mineras
      </Typography>
      <br />
      <Typography
        color="primary"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        Experiencia, conocimiento y actitud, a su servicio
      </Typography>
    </ProductHeroLayout>
  )
}

export default ProductHero
